<template>
    <main>
        <div class="container-data-princial" v-for="(viewDataArray, i) in listStudent" :key="i + 1">
            <div class="container-data-title">
                <h2>Información General</h2>
            </div>
            <div class="cont-data-new">
                <div class="mb-3" style="flex:1">
                    <div class="card text-bg-light card-name">
                        <div class="card-body" style="display: flex; flex-direction: column; justify-content: center;">
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <img class="rounded-circle image-profile-information mb-3" id="avatar" :src="dataUser.photo
                                    ? dataUser.photo.link
                                    : dataUser.image
                                        ? dataUser.image
                                        : imageUrl
                                    " />
                            </div>
                            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                <p style="font-size: 20px; font-family: Poppins;">
                                    {{ viewDataArray.name }}
                                    {{ viewDataArray.lastname }}
                                    {{ viewDataArray.secondlastname }}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div style="flex: 1;">
                    <div class="card text-bg-light  mb-3" style="width: 100%;" v-if="this.enableInput">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: column; font-family: 'Poppins';">
                                <div style="display: flex; flex-direction: row;">
                                    <div style="display:flex;justify-content:left; width: 85%;">
                                        <h6 style="font-weight: bold; font-size: 20px;">
                                            Datos de contacto y ubicación
                                        </h6>
                                    </div>
                                    <div style="display: flex;justify-content:right ;margin-bottom: 20px; width: 15%;">
                                        <box-icon name='edit-alt' style="cursor: pointer;"
                                            v-on:click="editInf()"></box-icon>
                                    </div>
                                </div>
                                <div>
                                    <div class="text-justify">
                                        <b>Celular:</b> {{ viewDataArray.phone }}
                                    </div>
                                </div>
                                <div>
                                    <div class="text-justify">
                                        <b>Email:</b> {{ viewDataArray.email }}
                                    </div>
                                </div>
                                <div>
                                    <div class="text-justify">
                                        <b>Ubicación: </b> {{ viewDataArray.delegation }}. {{ viewDataArray.municipaly }},
                                        {{ viewDataArray.estadoRepublica }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card text-bg-light mb-3" style="width: 100%;" v-else-if="!this.enableInput">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: column; font-family: 'Poppins';">
                                <h6 style="display: flex;justify-content:left; font-size: 20px;">
                                    Datos de contacto y ubicación
                                </h6>
                                &nbsp;
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" id="floatingInput"
                                        placeholder="Ingresa tu número celular" v-model="changePhone">
                                    <label for="floatingInput">Celular</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" id="floatingInput"
                                        placeholder="Ingresa tu correo electrónico" v-model="changeEmail">
                                    <label for="floatingInput">Ingresa tu correo electrónico</label>
                                </div>
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                                    <button id="change" class="btn btn-danger me-md-2" type="button" v-on:click="editInf()">
                                        Cancelar
                                    </button>
                                    <button class="btn btn-success" type="button"
                                        v-on:click="updateinfoPersonal()">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card text-bg-light  mb-3" style="width: 100%;" v-if="this.enableInput1">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: column; font-family: 'Poppins';">
                                <div style="display: flex; flex-direction: row;">
                                    <div style="display:flex;justify-content:left; width: 85%;">
                                        <h6 style="font-weight: bold; font-size: 20px;">
                                            Archivos y Enlaces
                                        </h6>
                                    </div>
                                    <div style="display: flex;justify-content:right ;margin-bottom: 20px; width: 15%;">
                                        <box-icon name='edit-alt' style="cursor: pointer;"
                                            v-on:click="editInf1()"></box-icon>
                                    </div>
                                </div>
                                <div style="display:flex; justify-content:center; align-items:center; gap: 2%">
                                    <div v-if="viewDataArray.linkedin">
                                        <a class="btn btn-primary btn-floating" title="LinkedIn" target="_blank"
                                            v-on:click="openLinkedin(viewDataArray.linkedin)">
                                            <box-icon name='linkedin' color="white" type='logo' ></box-icon>
                                        </a>
                                    </div>
                                    <div v-if="viewDataArray.filecv" style="display: flex; align-content: center; justify-content: center;">
                                        <a class="btn btn-primary btn-floating" title="CV" target="_blank"
                                            :href="viewDataArray.filecv?.link">
                                            <box-icon type='solid' color="white" name='file-pdf'></box-icon>
                                        </a>
                                    </div>
                                    <div v-if="viewDataArray.filecv">
                                        <a class="btn btn-primary btn-floating" title="Comprobante" target="_blank"
                                            :href="viewDataArray.filecomprobantes?.link">
                                            <box-icon type='solid' color="white" name='file-pdf'></box-icon>
                                        </a>
                                    </div>
                                </div>
                                <h6 class="mt-3">
                                    Los sitios de referencia ayudarán a que el reclutador conozca más sobre ti.
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="card text-bg-light  mb-3" style="width: 100%;" v-else-if="!this.enableInput1">
                        <div class="card-body">
                            <div style="display: flex; flex-direction: column; font-family: 'Poppins';">
                                <p style="display: flex;justify-content:left; font-weight: bold; font-size: 20px;">
                                    Archivos y Enlaces
                                </p>
                                <div style="display: flex; flex-direction: column;">
                                    <div style="margin-bottom: 15px;">
                                        <div class="input-group mb-3">
                                            <span class="input-group-text text-white" id="basic-addon1"
                                                style="background-color: #0e76a8">Linkedin</span>
                                            <input type="url" class="form-control" placeholder="Ingresa tu link"
                                                aria-label="Username" aria-describedby="basic-addon1"
                                                v-model="changeLinkedin" />
                                        </div>
                                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <button class="btn btn-success" type="button" v-on:click="updateLinkedin()">
                                                Guardar Nuevo Link
                                            </button>
                                        </div>
                                    </div>
                                    <hr>
                                    <div
                                        style="display: flex; flex-direction: column; justify-content: center; width: 100%;">
                                        <div style="display: flex; flex-direction: column; width: 100%;">
                                            <div v-show="viewDataArray.status === 'exuniversitario'">
                                                <UploadPartials
                                                    title="CONSTANCIA DE TERMINACIÓN DE ESTUDIOS, TITULÓ O CÉDULA"
                                                    @data="cambioComprobantes">
                                                </UploadPartials>
                                            </div>
                                            <div v-show="viewDataArray.status != 'exuniversitario'">
                                                <UploadPartials title="Constancia de Estudios Vigente"
                                                    @data="cambioComprobantes">
                                                </UploadPartials>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="display: flex; flex-direction: column; width: 100%;">
                                                <UploadPartials title="CV" @data="cambioCv"></UploadPartials>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                                    <button id="change" class="btn btn-danger me-md-2" type="button"
                                        v-on:click="editInf1()">
                                        Cancelar
                                    </button>
                                    <button class="btn btn-success" type="button" v-on:click="updatedocumentacion()">
                                        Guardar Documentos
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
</template>

<script>
import { UserService } from "@/services";
import moment from "moment";
import UploadPartials from "@/components/partials/upload.partials.vue";

moment.locale("es");

export default {
    mounted() {
        this.extractionInformation();
        this.dataUser = JSON.parse(localStorage.getItem("user") || null);
    },
    data: () => ({
        moment: moment,
        search: "",
        listStudent: {},
        linkedin: "",
        changeLinkedin: "",
        phone: "",
        changePhone: "",
        email: "",
        changeEmail: "",
        filecv: "",
        changefilecv: "",
        filecomprobantes: "",
        changefilecomprobantes: "",
        puesto: "",
        salarioAproximado: "",
        objetivoProfesional: "",
        enableInput: true,
        enableInput1: true,
        enableInput2: true,
    }),

    components: {
        UploadPartials,
    },

    methods: {
        async extractionInformation() {
            await UserService.getStudent().then((resp) => {
                let reverseData = resp.data;
                this.listStudent = reverseData;
                console.log(this.listStudent)
            });
        },
        openLinkedin(url) {
            let word = "https://"
            url.includes(word) ? window.open(url) : window.open("https://" + url);
        },
        //TODO: Calcular Edad 
        getEdad(dateString) {
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
                edad--
            }
            return edad
        },
        editInf() {
            this.enableInput == true ? this.enableInput = false : this.enableInput = true;
        },
        editInf1() {
            this.enableInput1 == true ? this.enableInput1 = false : this.enableInput1 = true;
        },
        editInf2() {
            this.enableInput2 == true ? this.enableInput2 = false : this.enableInput2 = true;
        },
        cambioComprobantes(data) {
            this.changefilecomprobantes = data;
        },
        cambioCv(data) {
            this.changefilecv = data;
        },
        updateinfoPersonal() {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const { _id } = user;
            let updateInformacionP = {
                studentId: this.$route.params.studentId = _id,
                phone: this.changePhone,
                email: this.changeEmail,
            }
            UserService.updateInformacionPersonal(updateInformacionP).then((response) => {
                window.location.href = "/informationstudent";
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
        },
        updateLinkedin() {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const { _id } = user;
            let updateLinkedin = {
                studentId: this.$route.params.studentId = _id,
                linkedin: this.changeLinkedin
            }
            UserService.updateSocialLinkedin(updateLinkedin).then((response) => {
                window.location.href = "/informationstudent";
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
        },
        updatedocumentacion() {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const { _id } = user;
            let updateDocumentos = {
                studentId: this.$route.params.studentId = _id,
                filecv: this.changefilecv,
                filecomprobantes: this.changefilecomprobantes,
            }
            UserService.updateDocumentacion(updateDocumentos).then((response) => {
                window.location.href = "/informationstudent";
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
        },
        updateObjetivoProfesional() {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const { _id } = user;
            let updateObjetivo = {
                puesto: this.puesto,
                salarioAproximado: this.salarioAproximado,
                objetivoProfesional: this.objetivoProfesional,
                studentId: this.$route.params.studentId = _id,
            }
            UserService.updateObjetivoProfesional(updateObjetivo).then((response) => {
                this.enableInput = true;
                window.location.href = "/informationstudent";
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
        },
    },
};
</script>
<style land="scss" scoped>
@import "../../styles/viewdatos.scss";
</style>